<template>
    <div class="big-box">
        <div class="middles">
            <div class="content">
                <div class="title">无人机1号</div>
            </div>
            <div class="content">
                <div class="title">无人机2号</div>
            </div>
            <div class="content">
                <div class="title">无人机3号</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return{}
    },
}
</script>

<style lang="scss">
        .middles{
            width: 1446px;
            height: 278px;
            margin: 25px auto 0;
            // border: 1px solid red;
            display: flex;
            .content{
                border: 1px solid pink;
                flex: 1;
                .title{
                    width: 212px;
                    height: 36px;
                    padding-left: 12px;
                    font-size: 18px;
                    font-family: PangMenZhengDao;
                    color: #C6E6FF;
                    line-height: 36px;
                    letter-spacing: 2px;
                    text-shadow: 0px 0px 6px #44B5FF;
                    background: linear-gradient(90deg, rgba(4, 20, 42, 0.7) 0%, rgba(4, 20, 42, 0) 100%);
                }
            }
        }
</style>