<template>
        <div class="big-box">
            <div class="bottom-box">
                <div class="bottom">
                    <div class="bottom-bigbox">
                        <div class="bottom-left">
                        <div class="bottom-left-top">
                            <div class="left-top-box">
                                <div class="left-top-ico"></div>
                                <div class="left-top-txt">
                                    <p>0%</p>
                                    <p>电量</p>
                                </div>
                            </div>
                            <div class="left-top-box">
                                <div class="left-top-ico"></div>
                                <div class="left-top-txt">
                                    <p>降落</p>
                                    <p>模式</p>
                                </div>
                            </div>
                            <div class="left-top-box">
                                <div class="left-top-ico"></div>
                                <div class="left-top-txt">
                                    <p>0个</p>
                                    <p>卫星</p>
                                </div>
                            </div>
                            <div class="left-top-box">
                                <div class="left-top-ico"></div>
                                <div class="left-top-txt">
                                    <p>0m/s</p>
                                    <p>飞速</p>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-left-bottom">
                            <div class="left-bottom-box">
                                <div class="left-bottom-box-ico"></div>
                                <div class="left-bottom-box-txt">
                                    <div class="txt-box">
                                        飞控系统：&nbsp;&nbsp;&nbsp;&nbsp;<span>未连接</span>
                                        <div class="txt-box-bottom"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="left-bottom-box">
                                <div class="left-bottom-box-ico"></div>
                                <div class="left-bottom-box-txt">
                                    <div class="txt-box">
                                        遥控系统：&nbsp;&nbsp;&nbsp;&nbsp;<span>未连接</span>
                                        <div class="txt-box-bottom"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="left-bottom-box">
                                <div class="left-bottom-box-ico"></div>
                                <div class="left-bottom-box-txt">
                                    <div class="txt-box">
                                        定位系统：&nbsp;&nbsp;&nbsp;&nbsp;<span>未连接</span>
                                        <div class="txt-box-bottom"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="left-bottom-box">
                                <div class="left-bottom-box-ico"></div>
                                <div class="left-bottom-box-txt">
                                    <div class="txt-box">
                                        RTK：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>未连接</span>
                                        <div class="txt-box-bottom"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="left-bottom-box">
                                <div class="left-bottom-box-ico"></div>
                                <div class="left-bottom-box-txt">
                                    <div class="txt-box">
                                        基站系统：&nbsp;&nbsp;&nbsp;&nbsp;<span>未知</span>
                                        <div class="txt-box-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="bottom-middle">
                            <div class="bottom-middle-left">
                                <div class="left-right-top" @click="jdkf">
                                    <div class="left-right-top-ico"></div>
                                    <span>打开</span>
                                </div>
                                <div class="left-right-bottom" @click="jdkf">
                                    <div class="left-right-top-ico"></div>
                                    <span>起降台</span>
                                </div>
                            </div>
                            <div class="bottom-middle-top">
                                <div class="bottom-middle-ybp">
                                    <div class="ybp-sj-box">
                                        <div class="ybp-sj-top">
                                            <div class="top-zz"></div>
                                            <span>20</span>
                                            <span>10</span>
                                        </div>
                                        <div class="ybp-sj-middle">
                                            <span>0</span>
                                            <span>0</span>
                                        </div>
                                        <div class="ybp-sj-bottom">
                                            <span>10</span>
                                            <span>20</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-middle-bottom">
                                <div class="big-bottom-middle-bottom">
                                    <div class="middle-bottom-wire"></div>
                                    <div class="middle-bottom-conten">
                                        <div>
                                            经度：<span>0</span>
                                        </div>
                                        <div>
                                            纬度：<span>0</span>
                                        </div>
                                        <div>
                                            高度：<span>0</span>
                                        </div>
                                        <div>
                                            海拔：<span>0</span>
                                        </div>
                                    </div>
                                    <div class="middle-bottom-wire"></div>
                                </div>
                            </div>
                            <div class="bottom-middle-right">
                                <div class="left-right-top" @click="jdkf">
                                    <div class="left-right-top-ico"></div>
                                    <span>放松</span>
                                </div>
                                <div class="left-right-bottom" @click="jdkf">
                                    <div class="left-right-top-ico"></div>
                                    <span>准飞</span>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-right">
                            <div class="bottom-right-bigbox">
                                <div class="bottom-right-top">
                                    <div class="bottom-right-top-txt">
                                        <p>0</p>
                                        <p>0</p>
                                    </div>
                                </div>
                                <div class="bottom-right-bottom">温度</div>
                            </div>
                            <div class="bottom-right-bigbox">
                                <div class="bottom-right-top">
                                    <div class="bottom-right-top-txt">
                                        <p>0</p>
                                        <p>0</p>
                                    </div>
                                </div>
                                <div class="bottom-right-bottom">湿度</div>
                            </div>
                            <div class="bottom-right-bigbox">
                                <div class="bottom-right-top">
                                    <div class="bottom-right-top-txt">
                                        <p>0m/s</p>
                                        <p>0deg</p>
                                    </div>
                                </div>
                                <div class="bottom-right-bottom">风速</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="middle-left middle-size">
                    <div class="earth">
                    </div>
                </div>
                <div class="middle-right middle-size" >
                    <div class="right-top" v-if="video">
                        <div v-for="(item,index) in choose" :key="index" @click="change(index)" :class="[currentindex==index?'active':'']" >
                            <span>{{item.size}}</span>
                        </div>
                        <!-- <div>
                            <span>六</span>
                        </div>
                        <div>
                            <span>八</span>
                        </div>
                        <div>
                            <span>十</span>
                        </div>
                        <div>
                            <span>十二</span>
                        </div>
                        <div>
                            <span>十四</span>
                        </div> -->
                    </div>
                    <div class="right-bottom" @click="video=!video">
                        <span>
                        视频模式
                        </span>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>

export default{
    data() {
        return{
            video:false,
            currentindex:'0',
            choose:[
                {
                    size:'四'
                },
                {
                    size:'六'
                },
                {
                    size:'八'
                },
                {
                    size:'十'
                },
                {
                    size:'十二'
                },
                {
                    size:'十四'
                },
            ]
        }
    },
    methods:{
        change(index){
            this.currentindex = index
            // console.log(111111);
        },
        jdkf(){
            this.$el_message("静待开放");
        }
    }
}
</script>

<style lang="scss">
.big-box{
    width: 100%;
    .bottom-box{
        width: 100%;
        height: 254px;
        position:fixed;
        left: 0;
        bottom: 0;
        .bottom{
            width: 1922px;
            height: 254px;
            margin: 0 auto;
            background-image: url("~@/assets/platform/aeronautical/bottom.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 10px;
            .bottom-bigbox{
                width: 100%;
                height: 254px;
                display: flex;
                justify-content: center;
                .bottom-left{
                    width: 25%;
                    height: 254px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    // border: 1px solid red;
                    .bottom-left-top{
                        display: flex;
                        margin-top: 3px;
                        margin-bottom: 25px;
                        justify-content: flex-end;
                        .left-top-box{
                            padding:70px 0 0 0;
                            display: flex;
                            align-items: center;
                            margin-right: 19px;
                            .left-top-ico{
                                width: 34px;
                                height: 34px;
                                margin-right: 8px;
                                background-image: url("~@/assets/platform/aeronautical/battery.png");
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                            .left-top-txt{
                                p{
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #B5CCFF;
                                line-height: 20px;
                                }
                                p:nth-child(1){
                                    font-size: 20px;
                                    font-family: PangMenZhengDao;
                                    color: #488CFF;
                                    line-height: 23px;
                                }
                            }
                        }
                        div:nth-child(1){
                            
                        }
                        div:nth-child(2){
                            .left-top-ico{
                                background-image: url("~@/assets/platform/aeronautical/landing.png");
                            }
                            
                        }
                        div:nth-child(3){
                            .left-top-ico{
                                background-image: url("~@/assets/platform/aeronautical/satellite.png");
                            }
                        }
                        div:nth-child(4){
                            margin-right: 5px;
                            // margin-right: 0px;
                            .left-top-ico{
                                background-image: url("~@/assets/platform/aeronautical/speed.png");
                            }
                            
                        }
                    }
                    .bottom-left-bottom{
                        width: 438px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-left: 14px;
                        .left-bottom-box{
                            display: flex;
                            align-items: center;
                            margin-right: 18px;
                            margin-bottom: 8px;
                            .left-bottom-box-ico{
                                width: 26px;
                                height: 26px;
                                margin-right: 10px;
                                background-image: url("~@/assets/platform/aeronautical/drone.png");
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                            .left-bottom-box-txt{
                                .txt-box{
                                    width: 157px;
                                    height: 28px;
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #B5CCFF;
                                    line-height: 28px;
                                    .txt-box-bottom{
                                        width: 100%;
                                        height: 1px;
                                        border-bottom: 1px solid;
                                        border-image: linear-gradient(270deg, rgba(25, 136, 234, 0), rgb(25, 136, 234), rgba(25, 136, 234, 0)) 1 1;
                                    }
                                }
                            }
                        }
                        div:nth-child(2){
                            .left-bottom-box-ico{
                                background-image: url("~@/assets/platform/aeronautical/dwtb.png");
                            }
                        }
                        div:nth-child(3){
                            .left-bottom-box-ico{
                                background-image: url("~@/assets/platform/aeronautical/yktb.png");
                            }
                        }
                        div:nth-child(4){
                            .left-bottom-box-ico{
                                background-image: url("~@/assets/platform/aeronautical/ljtb.png");
                            }
                        }
                        div:nth-child(5){
                            .left-bottom-box-ico{
                                background-image: url("~@/assets/platform/aeronautical/jztb.png");
                            }
                        }
                    }
                }
                .bottom-middle{
                    width: 26%;
                    height: 254px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    .bottom-middle-top{
                        width: 283px;
                        height: 274px;
                        position: absolute;
                        left: 90px;
                        top: -63px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-image: url("~@/assets/platform/aeronautical/ybpdz.png");
                        background-repeat: no-repeat;
                        background-position-y: 111px;
                        .bottom-middle-ybp{
                            width: 228px;
                            height: 240px;
                            background-image: url("~@/assets/platform/aeronautical/ybp.png");
                            background-repeat: no-repeat;
                            background-position-y: -6px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .ybp-sj-box{
                                width: 110px;
                                height: 164px;
                                font-size: 16px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 22px;
                                margin-bottom: 11px;
                                .ybp-sj-top{
                                    display: flex;
                                    flex-direction: column;
                                    // text-align: center;
                                    align-items: center;
                                    .top-zz{
                                        width: 7px;
                                        height: 7px;
                                        margin-bottom: 10px;
                                        background: url("~@/assets/platform/aeronautical/top-zz.png");
                                    }
                                    span:nth-child(2){
                                        margin-bottom: 2px;
                                    }
                                }
                                .ybp-sj-middle{
                                    display: flex;
                                    margin-top: 2px;
                                    margin-bottom: 2px;
                                    justify-content: space-between;
                                }
                                .ybp-sj-bottom{
                                    display: flex;
                                    text-align: center;
                                    flex-direction: column;
                                    span:nth-child(1){
                                        margin-bottom: 2px;
                                    }
                                }
                            }
                        }

                    }
                    .bottom-middle-bottom{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        position: absolute;
                        bottom: 4px;
                        .big-bottom-middle-bottom{
                            .middle-bottom-conten{
                                display: flex;
                                justify-content: space-around;
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #DDE8FF;
                                margin: 4px 0;
                                div{
                                    margin-right: 38px;
                                }
                            }
                            .middle-bottom-wire{
                                width: 534px;
                                height: 1px;
                                border-bottom: 1px solid;
                                border-image: linear-gradient(270deg, rgba(25, 136, 234, 0), rgb(25, 136, 234), rgba(25, 136, 234, 0)) 1 1;
                            }
                        }
                        
                    }
                    .bottom-middle-left{
                        
                        .left-right-top{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            position: absolute;
                            left: 36px;
                            top: 72px;
                            .left-right-top-ico{
                                width: 52px;
                                height: 30px;
                                background-image: url("~@/assets/platform/aeronautical/open.png");
                                background-repeat: no-repeat;
                                background-size: cover; 
                            }
                            span{
                                margin-top: 8px;
                                text-align: center;
                                font-size: 16px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #B5CCFF;
                            }
                        }
                        .left-right-bottom{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            position: absolute;
                            left: 6px;
                            bottom: 45px;
                            .left-right-top-ico{
                                width: 52px;
                                height: 30px;
                                background-image: url("~@/assets/platform/aeronautical/landingpad.png");
                                background-repeat: no-repeat;
                                background-size: cover; 
                            }
                            span{
                                margin-top: 8px;
                                text-align: center;
                                font-size: 16px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #B5CCFF;
                            }
                        }
                    }
                    .bottom-middle-right{
                        
                        .left-right-top{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            position: absolute;
                            right: 74px;
                            top: 72px;
                            .left-right-top-ico{
                                width: 52px;
                                height: 30px;
                                background-image: url("~@/assets/platform/aeronautical/relax.png");
                                background-repeat: no-repeat;
                                background-size: cover; 
                            }
                            span{
                                margin-top: 8px;
                                text-align: center;
                                font-size: 16px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #B5CCFF;
                            }
                        }
                        .left-right-bottom{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            position: absolute;
                            right: 44px;
                            bottom: 45px;
                            .left-right-top-ico{
                                width: 52px;
                                height: 30px;
                                background-image: url("~@/assets/platform/aeronautical/readytofly.png");
                                background-repeat: no-repeat;
                                background-size: cover; 
                            }
                            span{
                                margin-top: 8px;
                                text-align: center;
                                font-size: 16px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #B5CCFF;
                            }
                        }
                    }
                }
                .bottom-right{
                    width: 23%;
                    height: 165px;
                    margin-top: 87px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .bottom-right-bigbox{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 24px;
                        .bottom-right-top{
                            width: 108px;
                            height: 110px;
                            margin-bottom: 12px;
                            background-image: url("~@/assets/platform/aeronautical/lukewarm.png");
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .bottom-right-top-txt{
                                height: 60px;
                                font-size: 18px;
                                font-family: PangMenZhengDao;
                                color: #B5CCFF;
                                p:nth-child(1){
                                    margin-top: 4px;
                                }
                                p:nth-child(2){
                                    margin-top: 16px;
                                }
                            }

                        }
                        .bottom-right-bottom{
                            width: 86px;
                            height: 17px;
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #B5CEFF;
                            text-align: center;
                            background-image: url("~@/assets/platform/aeronautical/bgtb.png");
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                        }
                    }
                    div:nth-child(2){
                        .bottom-right-top{
                            background-image: url("~@/assets/platform/aeronautical/wet.png");
                        }
                    }
                    div:nth-child(3){
                        .bottom-right-top{
                            background-image: url("~@/assets/platform/aeronautical/wind.png");
                        }
                    }
                }
            }

        }
        .middle-size{
                width: 276px;
                height: 759px;
                position:fixed;
                background-repeat: no-repeat;
                background-size: cover;
        }
        .middle-left{
            bottom: 0;
            left: 0;
            background-image: url("~@/assets/platform/aeronautical/middle-left.png");
            .earth{
                width: 150px;
                height: 150px;
                position: absolute;
                bottom: 68px;
                left: 34px;
                cursor: pointer;
                background-image: url("~@/assets/platform/aeronautical/earth.png");
            }
        }
        .middle-right{
            bottom: 0;
            right: 0;
            background-image: url("~@/assets/platform/aeronautical/middle-right.png");
            background-repeat: no-repeat;
            background-position: center;
            .right-top{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: absolute;
                bottom: 250px;
                right: 0;
                background-image: url("~@/assets/platform/aeronautical/middlex.png");
                background-position: 2px 0;
                div{
                    height: 47px;
                    width: 174px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #7EB8FF;
                    border-top: none;
                    color: #C6E6FF;
                    background: linear-gradient(90deg, rgba(52, 138, 228, 0) 0%, rgba(40, 115, 193, 0.3) 100%);
                    span{
                        height: 18px;
                        font-size: 20px;
                        display: inline-block;
                        font-family: PangMenZhengDao;
                        line-height: 21px;
                        cursor: pointer;
                        text-shadow: 0px 0px 3px rgba(68, 181, 255, 0.5);
                        &:hover{
                            color: #50FFFB;
                        }
                    }
                    
                }
                .active{
                    background: #1AA3FF;
                    color: #50FFFB;
                } 
                
                div:nth-child(1){
                    width: 92px;
                    border-top: 1px solid #7EB8FF;
                    // border-radius: 30px 0px 0px 0px;
                    // background: pink;
                    clip-path: polygon(20% 8%, 37% 0, 100% 0, 100% 100%, 80% 100%, 0 99%, 3% 63%, 9% 30%);
                }
                div:nth-child(2){
                    width: 116px;
                    // background: pink;
                    clip-path: polygon(20% 0, 100% 0, 100% 100%, 80% 100%, 7% 100%);
                }
                div:nth-child(3){
                    width: 130px;
                    // background: pink;
                    clip-path: polygon(17% 0, 100% 0, 100% 100%, 80% 100%, 6% 100%);
                }
                div:nth-child(4){
                    width: 146px;
                    // background: pink;   
                    clip-path: polygon(16% 0, 100% 0, 100% 100%, 80% 100%, 7% 100%);
                }
                div:nth-child(5){
                    width: 163px;
                    // background: pink;   
                    clip-path: polygon(16% 0, 100% 0, 100% 100%, 80% 100%, 8% 100%);
                }
                div:nth-child(6){
                    width: 174px;
                    // background: pink;   
                    clip-path: polygon(13% 0, 100% 0, 100% 100%, 80% 100%, 6% 100%);
                }
            }
            .right-bottom{
                width: 150px;
                height: 150px;
                position: absolute;
                bottom: 68px;
                right: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url("~@/assets/platform/aeronautical/videomode.png");
                background-repeat: no-repeat;
                background-position: center;
                span{
                    width: 57px;
                    height: 52px;
                    display: inline-block;
                    font-size: 24px;
                    font-family: PangMenZhengDao;
                    color: #C6E6FF;
                    line-height: 26px;
                    letter-spacing: 5px;
                    text-shadow: 0px 0px 6px #44B5FF;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
