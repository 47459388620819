<template>
    <div class="aeronaut">
        <div class="top">
            <div class="left">
                <div class="left-right">
                    <div class="left-left">
                        <div class="left-text">
                            <div class="text">机巢内视频</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="top-round">
                <div class="round-left"></div>
                <div class="round"></div>
                <div class="round-right"></div>
            </div> -->
            <div class="right">
                <div class="right-left">
                    <div class="right-right">
                        <div class="right-text">
                            <div class="text">机巢外视频</div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="bgtu"></div>
        <Dashboard />
        <Middle />
    </div>
</template>
<script>
import Dashboard from "./dashboard"
import Middle from "./middle"
    export default{
        components:{
            Middle,
            Dashboard,
        },
        data(){
            return{}
        },
    }
</script>
<style lang="scss" scoped>
    .aeronaut {
        width: 100%;
        box-sizing: border-box;
        padding: 0 32px 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        // flex-wrap: wrap;
        .top {
            width: 1860px;
            height: 385px;
            // background: url("~@/assets/platform/aeronautical/top.png") no-repeat;
            background-size: cover;
            display: flex;
            justify-content: space-between;
            padding: 0 13px;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            z-index: 2;
            .left {
                width: calc(50% - 10px);
                display: flex;
                justify-content: center;
                position: absolute;
                z-index: 1;
                .left-right {
                    width: 884px;
                    height: 349px;
                    border: 1px solid red;
                    background: pink;
                    clip-path: polygon(6% 4%, 79% 12%, 100% 12%, 100% 90%, 79% 90%, 11% 98%, 1% 20%);
                    .left-left {
                        width: 212px;
                        height: 36px;
                        background: linear-gradient(90deg, rgba(4, 20, 42, 0.7) 0%, rgba(4, 20, 42, 0) 100%);
                        display: flex;
                        align-items: center;
                        padding-left: 30px;
                        margin-top: 13px;
                        .left-text {
                            width: 212px;
                            height: 18px;
                            .text{
                                width: 212px;
                                height: 18px;
                                font-size: 18px;
                                font-family: PangMenZhengDao;
                                color: #C6E6FF;
                                line-height: 21px;
                                letter-spacing: 2px;
                                text-shadow: 0px 0px 6px #44B5FF;
                                padding-left: 24px;
                            }
                        }
                    }
                }
            }
            .right {
                width: calc(50% - 10px);
                display: flex;
                position: absolute;
                right: 0px;
                z-index: 1;
                .right-left {
                    width: 884px;
                    height: 349px;
                    border: 1px solid red;
                    background: pink;
                    display: flex;
                    flex-direction: row-reverse;
                    clip-path: polygon(21% 12%, 95% 5%, 100% 21%, 89% 100%, 21% 90%, 0 90%, 0 12%);
                    .right-right {
                        width: 212px;
                        height: 36px;
                        background: linear-gradient(270deg, rgba(4, 20, 42, 0.7) 0%, rgba(4, 20, 42, 0) 100%);
                        display: flex;
                        align-items: center;
                        padding-right: 44px;
                        margin-top: 16px;
                        .right-text {
                            width: 212px;
                            height: 18px;
                            .text{
                                width: 212px;
                                height: 18px;
                                font-size: 18px;
                                font-family: PangMenZhengDao;
                                color: #C6E6FF;
                                text-align: right;
                                line-height: 21px;
                                letter-spacing: 2px;
                                text-shadow: 0px 0px 6px #44B5FF;
                            }
                        }
                    }
                }
            }
            .top-round{
                width: 257px;
                height: 19px;
                background: linear-gradient(25deg, rgba(36, 144, 239, 0) 0%, #2490EF 100%);
                .round{
                    width: 8px;
                    height: 8px;
                    margin: 0 6px;
                    border-radius: 50%;
                }
            }
        }
        .bgtu{
            width: 1796px;
            height: 382px;
            margin: 0 auto;
            padding: 0 25px;
            background: url("~@/assets/platform/aeronautical/top.png") no-repeat;
            // background: url("~@/assets/platform/aeronautical/top2.png") no-repeat;
            background-size: cover;
            position: absolute;
            top: 0px;
            z-index: 2;
            display: flex;
            
        }
    }
</style>